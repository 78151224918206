import { LoadingAppView } from '@hospy/shared-components';
import {
	APPS,
	fetchGetAppData,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
// import Layout from 'antd/lib/layout/layout';
import { AnimatePresence } from 'framer-motion';
import IndeterminateLoading from 'libs/hospy-ui/src/lib/atoms/indeterminate-loading';
import React, { useEffect } from 'react';

import AppRoutes from '../routes/app-routes';
import { setIntercomUser } from '@hospy/util-common';

const App = () => {
	const { isAuth, user } = useAppSelector(({ user }) => user);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchGetAppData(APPS.SIRE_TRA));
	}, []);

	if (isAuth && !user) return <LoadingAppView />;

	setIntercomUser(user);
	return (
		<>
			<React.Suspense fallback={<IndeterminateLoading />}>
				<AnimatePresence>
					<AppRoutes />
				</AnimatePresence>
			</React.Suspense>
		</>
	);
};

export default App;
