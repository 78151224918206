import { accountRoute, lazyWithRetry } from '@hospy/shared-components';
import { useRoutes } from 'react-router-dom';

const PrivateLayout = lazyWithRetry(
	() => import('@sire/components/layouts/private-layouts')
);

const DashboardLayout = lazyWithRetry(
	() => import('@sire/components/layouts/dashboard-layout')
);

const PartnerOauth = lazyWithRetry(() => import('@sire-pages/oauth-popup'));
const AirbnbReservationForm = lazyWithRetry(
	() => import('@sire-pages/airbnb-reservation-form')
);
const AirbnbConnect1 = lazyWithRetry(
	() => import('@sire-pages/airbnb-tra-sire-connect')
);

const AppRoutes = () => {
	const routes = useRoutes([
		...accountRoute.map(({ path, component: Cmp }) => ({
			key: path,
			path,
			element: <Cmp />
		})),
		{
			path: 'partner/oauth',
			element: <PartnerOauth />
		},
		{
			path: '/airbnb/:propertyId/:reservationCode',
			element: <AirbnbReservationForm />
		},
		{
			path: '/airbnb/:propertyId/:reservationCode/:price',
			element: <AirbnbReservationForm />
		},
		{
			path: '/airbnb/:propertyId/:reservationCode/:price/:price1',
			element: <AirbnbReservationForm />
		},
		{
			path: '/airbnb/tra_sire_connects',
			element: <AirbnbConnect1 />
		},
		{
			path: '/airbnb/tra_sire_connects/:payment',
			element: <AirbnbConnect1 />
		},
		{
			element: <PrivateLayout />,
			children: [
				{
					path: '*',
					element: <DashboardLayout />
				}
			]
		}
	]);
	return routes;
};
export default AppRoutes;
