import { ReactElement } from 'react';
import { Card, CardProps } from '../../atoms/card/card';
import { SectionEditorProps } from '../../molecules/section-editor/section-editor';

export interface CardSectionEditorProps extends CardProps {
	style?: React.CSSProperties;
	section:
		| ReactElement<SectionEditorProps>
		| Array<ReactElement<SectionEditorProps>>;
}

export function CardSectionEditor({
	section,
	...props
}: CardSectionEditorProps) {
	return (
		<Card
			titleStyle={{ fontWeight: 'bold' }}
			bodyStyle={{ display: 'flex', flexDirection: 'column', gap: 30 }}
			{...props}
		>
			{section}
		</Card>
	);
}

export default CardSectionEditor;
