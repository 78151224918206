/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppSelector } from '@hospy/store';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import SelectProperty from '../select-property';

interface IUseAppNavBar {
	hideSelectProperty?: boolean;
	title: string;
}

const UseAppNavBar = ({ hideSelectProperty, title }: IUseAppNavBar) => {
	const theme: any = useTheme();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { user } = useAppSelector(({ user }) => user);

	const [open, setOpen] = useState<boolean>(false);

	const [modalBuyDenied, setModalBuyDenied] = useState<{
		open: boolean;
		name: string;
	}>({ open: false, name: '' });

	const sProperty = hideSelectProperty ? null : (
		<SelectProperty
			hideAddPropertyButton={true}
			appName={title}
			onCloseDrawer={() => setOpen(false)}
		/>
	);

	useEffect(() => {
		setOpen(false);
	}, [pathname]);

	const exitViewUser = () => {
		localStorage.removeItem('userIdView');
		window.location.href = process.env['NX_APP_ADMIN'] + 'users';
	};

	const buy = () => {
		const isOwner = user?.properties?.some(
			(e) => e._id === user.currentProperty && e.rol === 'owner'
		);

		if (!isOwner)
			return setModalBuyDenied({
				open: true,
				name: ` ${
					user?.properties?.find(
						(property) => property._id === user.currentProperty
					)?.name || ''
				}`
			});
		return navigate('/subscriptions/create');
	};

	return {
		buy,
		exitViewUser,
		open,
		modalBuyDenied,
		sProperty,
		theme,
		user,
		setOpen,
		setModalBuyDenied
	};
};

export default UseAppNavBar;
