import { GlobalStyles } from '@hospy/hospy-ui';
import {
	backAppsColors,
	HospyThemeProvider,
	SessionMonitoring,
	TranslateProvider,
	ValidateAuth
} from '@hospy/shared-components';
import { store } from '@hospy/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundaryFallback from 'libs/hospy-ui/src/lib/organisms/error-boundary-fallback';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './base-theme.css';
import App from './components/app/app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import FeedbackEnableCookies from 'libs/hospy-ui/src/lib/organisms/feedback-enable-cookies';
import { IntercomProvider } from 'react-use-intercom';

Sentry.init({
	dsn: 'https://f95c9fd0886d439994a3951b2719c35c@o4504636114927616.ingest.sentry.io/4504663434330112',
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new BrowserTracing()
		//new Sentry.Replay()
	],
	environment: process.env['NODE_ENV'] || 'development'
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Provider store={store}>
		<GlobalStyles />
		<FeedbackEnableCookies>
			<ValidateAuth>
				<BrowserRouter>
					<SessionMonitoring />
					<HospyThemeProvider colors={backAppsColors}>
						<TranslateProvider
							app="colombia-sire-tra"
							defaultMessagesFunction={
								import('./translations/compiled-lang/es.json')
							}
						>
							<Sentry.ErrorBoundary
								fallback={(props) => (
									<ErrorBoundaryFallback {...props} />
								)}
							>
								<IntercomProvider
									autoBoot={true}
									appId="kpy3ar74"
								>
									<App />
								</IntercomProvider>
							</Sentry.ErrorBoundary>
						</TranslateProvider>
					</HospyThemeProvider>
				</BrowserRouter>
			</ValidateAuth>
		</FeedbackEnableCookies>
	</Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
